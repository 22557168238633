$(document).ready(function () {
    $('.active .answer').slideDown(0);
    $('.que').on('click', function () {
        if ($(this).hasClass('active')) {} else {
            $('.que').removeClass('active');
            $(this).addClass('active');
            $('.answer').slideUp();
            $('.active .answer').slideDown();
        }
    });
    //menu 
    var menuWidth = $('.menu-mobile').width() + 40;
    $('.fa-bars').on('click', function() {
        $('.wrapper').css({
            'transform':'translateX(-' + menuWidth + 'px)',
        });
        $('body').css({
            'overflowY':'hidden'
        });
        $('.menu-mobile').css({
            'right':'0',
            'display':'block'
        });
    });
    $('.close').on('click', function() {
        $('.wrapper').css({
            'transform':'translateX(0px)',
        });
        $('body').css({
            'overflowY':'scroll'
        });
        $('.menu-mobile').css({
            'display':'none',
            'right': '100%'
        });
    })
    $('.timecodes .item').on('click', function(e) {
        var attr = $(this).attr('data-num');
        var vid = $('iframe');
        console.log(vid);
        vid.fadeOut(1);
        if(attr == 'one') {
            $('iframe#one').fadeIn(1);
        }
        if(attr == 'two') {
            $('iframe#two').fadeIn(1);
        }
        if(attr == 'three') {
            $('iframe#three').fadeIn(1);
        }
        if(attr == 'four') {
            $('iframe#four').fadeIn(1);
        }
        if(attr == 'five') {
            $('iframe#five').fadeIn(1);
        }
        if(attr == 'six') {
            $('iframe#six').fadeIn(1);
        }
        $('.timecodes .item').removeClass('active');
        $(this).addClass('active')
    })
    $('.timecodes .item a').on('click', function(e) {
        e.preventDefault();
    })
});